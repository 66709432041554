import React from "react";
import RoadmapItem from "./RoadmapItem";

function Roadmap() {
  return (
    <div
      className="flex flex-col w-full bg-cover bg-gradient-to-t to-white from-[#321C4A] font-hauora z-10"
      id="roadmap"
    >
      <div className="backdrop-contrast-75 backdrop-blur-sm flex flex-col items-center justify-evenly h-auto w-auto bg-cover ">
        <div className="text-5xl mt-10  text-herocolor font-semibold   ">
          <p>Roadmap</p>
        </div>

        <div className="flex flex-col mt-10 mb-10   md:flex-col xl:flex-row 2xl:flex-row w-full h-full gap-10 px-6 z-10">
          <RoadmapItem
            items={[
              {
                title: "Building the community and a core team.",
                color: "bg-green-700",
              },
              {
                title: "Creation of the social media accounts",
                color: "bg-green-700",
              },
              {
                title: "Deployment of the contract",
                color: "bg-green-700",
              },
              { title: "Verification on BscScan", color: "bg-green-700" },
              {
                title: "Launch of the Website",
                color: "bg-green-700",
              },

              {
                title: "Initial liquidity locked",
                color: "bg-green-700",
              },
            ]}
            stage={"Q1 - 2024"}
          />
          <RoadmapItem
            items={[
              {
                title: "Launch on PancakeSwap",
                color: "bg-green-700",
              },
              {
                title: "Advertising and marketing                ",
                color: "bg-white",
              },

              {
                title: "Listing on the Coingecko                ",
                color: "bg-white",
              },
              {
                title: "Listing on the CoinMarketCap                ",
                color: "bg-white",
              },
            ]}
            stage={"Q2 - 2024"}
          />
          <RoadmapItem
            items={[
              {
                title: "Upgrade to Website v2",
                color: "bg-white",
              },
              {
                title: "Meetings with CEXs",
                color: "bg-white",
              },
              {
                title: "The first minor exchange listing.",
                color: "bg-white",
              },

              {
                title: "Tier 2 Central Exchange",
                color: "bg-white",
              },
            ]}
            stage={"Q3 - 2024"}
          />
          <RoadmapItem
            items={[
              {
                title: "Ecosystem Development",
                color: "bg-white",
              },

              {
                title: "More...",
                color: "bg-white",
              },
            ]}
            stage={"Q4 - 2024"}
          />
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
