import React from 'react';

function ScrollItems({ item, href }) {
  return (
    <div className="mx-1 underline">
      <a href={href}>{item}</a>
    </div>
  );
}

export default ScrollItems;
