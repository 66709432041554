import React from "react";

function HowToBuyItems({ rank, desc }) {
  return (
    <div className=" flex p-1 ">
      <span className="text-white px-2   ">{rank}</span>
      <p className="text-white">{desc}</p>
    </div>
  );
}

export default HowToBuyItems;
