import React from "react";
import HeaderItem from "./HeaderItem";
import {
  CashIcon,
  CubeTransparentIcon,
  FingerPrintIcon,
  HomeIcon,
  MapIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  ViewGridIcon,
  AcademicCapIcon,
} from "@heroicons/react/outline";

function Header() {
  return (
    <header className="flex flex-col sm:flex-row  justify-between items-center h-auto w-auto sticky top-0 z-50  bg-gray-100 px-3 ">
      <div className="flex cursor-pointer justify-center items-center">
        <p className="text-xl font-semibold font-pressStart2P text-herocolor">
          snitch
        </p>
      </div>
      <div className="flex flex-grow justify-evenly max-w-4xl items-center">
        <HeaderItem title="Home" Icon={HomeIcon} href="#hero" />
        <HeaderItem
          title="Tokenomics"
          Icon={CubeTransparentIcon}
          href="#tokenomics"
        />
        <HeaderItem title="Roadmap" Icon={MapIcon} href="#roadmap" />
        <HeaderItem title="Spells" Icon={AcademicCapIcon} href="#projects" />
      </div>
    </header>
  );
}

export default Header;
