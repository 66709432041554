import React from "react";
import HowToBuyItems from "./HowToBuyItems";
import { DuplicateIcon } from "@heroicons/react/outline";
import Exchanges from "./Exchanges";

function HowToBuy() {
  function copyFunction() {
    /* Get the text field */
    var copyText = document.getElementById("copyInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  }
  return (
    <div
      className="flex flex-col w-full bg-cover bg-gradient-to-t to-gray-300 from-[#321C4A] font-hauora z-50"
      id="howtobuy"
    >
      <div className="backdrop-contrast-75 backdrop-blur-sm flex flex-col items-center justify-evenly h-auto w-auto bg-cover ">
        <div className=" text-5xl sm:text-5xl mt-10 font-semibold text-herocolor items-center justify-center z-50 ">
          <p>How to buy Snitch</p>
        </div>

        <div className="flex flex-col mt-10  bg-herocolor bg-opacity-80 p-4 rounded-lg ">
          <HowToBuyItems
            rank={"1. "}
            desc={"Connect your wallet to Metamask or Trust Wallet"}
          />
          <HowToBuyItems rank={"2. "} desc={"Deposit BNB into your wallet"} />
          <HowToBuyItems
            rank={"3. "}
            desc={"Trust Wallet only: swap BNB to Smart Chain"}
          />
          <HowToBuyItems rank={"4. "} desc={"Go to Pancakeswap V2"} />
          <HowToBuyItems
            rank={"5. "}
            desc={
              "Select a currency and enter the Snitch contract address into the search field:"
            }
          />
          <div className="flex items-center w-full justify-between  relative bg-gray-300 px-2 py-1 rounded-l-3xl cursor-pointer focus:outline-none text-sm text-center shadow-sm shadow-black sm:w-96 border-l-4 border-herocolor">
            <input
              className="w-full bg-transparent border-none outline-none mx-1"
              type="text"
              value="0x7e0485641b244376bba153b62eb99e1c83448c6f"
              id="copyInput"
            />

            <DuplicateIcon
              onClick={copyFunction}
              class="w-6 h-6 text-herocolor transition  ease-in-out delay-150 hover:-translate-z-1 hover:scale-110  duration-75 "
            />
          </div>
          <HowToBuyItems
            rank={"6. "}
            desc={"Click the cogwheel and set the slippage"}
          />
          <HowToBuyItems
            rank={"7. "}
            desc={
              "Set the amount you want to purchase and press the exchange swap button"
            }
          />
          <HowToBuyItems rank={"8. "} desc={"Confirm the transaction"} />
        </div>
        <Exchanges />
      </div>
    </div>
  );
}

export default HowToBuy;
