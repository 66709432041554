import React from "react";
import StageItems from "./StageItems";

function RoadmapItem({ items, stage }) {
  return (
    <div class="w-auto mx-auto relative py-2  z-10 ">
      <h1 class="text-3xl text-center  text-herocolor font-semibold">
        {" "}
        {stage}
      </h1>

      <div class="border-l-2 mt-10  z-50 ">
        {items.map((item) => (
          <StageItems key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default RoadmapItem;
