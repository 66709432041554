import React from "react";
import VisionItems from "./VisionItems";
import ScrollItems from "./ScrollItems";
import { DuplicateIcon } from "@heroicons/react/outline";

function Hero() {
  function myFunction() {
    /* Get the text field */
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  }
  return (
    <div className="flex flex-col " id="full">
      <div
        className="flex flex-col gap-2 sm:flex-row items-center justify-center h-full xl:py-[50px] 2xl:py-[100px]  w-full bg-cover bg-gradient-to-t to-[#E1D2EE] from-[#29163B]"
        id="hero"
      >
        <div className="flex flex-col  relative z-10 sm:w-1/3 md:w-1/3 xl:w-1/3 2xl:w-[600px] mx-20">
          <img className="sm:mb-72" alt="snitch logo" src={"/snitch.png"} />
        </div>
        <div className="p-1 flex flex-col items-center sm:w-1/2  w-full  h-fit text-center  rounded-lg text-white z-10">
          <p className="text-center text-3xl mb-6 font-black text-herocolor font-hauora">
            A deflationary meme token with 1 % auto-burn mechanism. Snitch is
            not just a token; it's a magical community adventure.
          </p>

          <div className="mx-2 border-black rounded-xl border-none p-8 bg-gradient-to-br to-herobg1 from-herobg2 ">
            <p className="text-center text-xl font-hauora">
              Let's go to the Moon in the Crypto Wizarding World! 🚀🌕
            </p>

            <p className="text-base text-center mt-4  font-hauora">
              Welcome to the enchanted halls of Snitch, where the magic is not
              just in the token but in the community that shapes its destiny!
              🧙‍♂️🏰
            </p>

            <p className="text-base text-center mt-2 font-hauora ">
              Snitch is not just a token; it's a magical community adventure.
              Join us as we collectively weave the magic that defines the
              essence of Snitch. Your participation is the wand that guides our
              success, and together, we'll soar to new heights, reaching the
              moon and beyond in the crypto wizarding world!
            </p>

            <p className="text-base text-center mt-2  font-hauora">
              Just like the Golden Snitch in Quidditch, Snitch is uncatchable by
              other tokens. It dances through the crypto skies, evading any
              attempt to seize its magic. Other tokens may try, but the Snitch
              remains elusive and free.
            </p>
          </div>

          <div className="flex flex-row w-full justify-center items-center mt-8">
            <div>
              {" "}
              <p className="text-base text-center underline mx-2 font-hauora">
                %5 trading tax
              </p>
              <p className="text-base text-center underline mx-2 font-hauora">
                zero transferring tax
              </p>
            </div>

            <a
              className="p-1"
              href="https://pancakeswap.finance/swap?outputCurrency=0x7e0485641b244376bba153b62eb99e1c83448c6f"
              target="_blank"
            >
              <button class="bg-herocolor text-sm hover:bg-gray-500 text-white  py-1 px-6 rounded-lg border border-opacity-20 border-black font-pressStart2P">
                Buy
              </button>
            </a>
            <a
              className="p-1"
              href="https://www.dextools.io/app/bsc/pair-explorer/"
              target="_blank"
            >
              <button class="bg-herocolor  hover:bg-gray-500 text-white  py-1 px-6 rounded-lg border border-opacity-20 border-black font-pressStart2P text-sm">
                Chart
              </button>
            </a>
          </div>

          <div className="flex items-center w-full justify-between mt-4 relative bg-gray-300 px-2 py-1 rounded-l-3xl cursor-pointer focus:outline-none text-sm text-center shadow-sm shadow-black sm:w-96 border-l-4 border-herocolor">
            <input
              className="w-full bg-transparent border-none outline-none mx-1 text-black font-hauora"
              type="text"
              value="0x7e0485641b244376bba153b62eb99e1c83448c6f"
              id="myInput"
            />

            <DuplicateIcon
              onClick={myFunction}
              class="w-6 h-6 text-herocolor transition  ease-in-out delay-150 hover:-translate-z-1 hover:scale-110  duration-75 "
            />
          </div>
          <div className="flex mt-5">
            <p className="text-lg text-left font-hauora">Follow us on..</p>
          </div>
          <div className="flex w-full items-center justify-center m-2">
            <a href="https://t.me/snitchtoken" target="_blank" className="m-2">
              <img src={"/social/telegram.png"} width={32} height={32} />{" "}
            </a>
            <a className="m-2" href="https://x.com/snitchtoken" target="_blank">
              <img src={"/social/twitter.png"} width={32} height={32} />
            </a>
          </div>

          <div className="flex flex-col w-full items-center justify-center m-2">
            <div className="m-2">
              <p className="text-lg font-hauora">Our vision starts here:</p>
            </div>

            <div className="flex flex-row w-full items-center justify-center gap-3">
              <VisionItems
                title={"Ownership Renounced"}
                alttitle={"Check"}
                href={"https://bscscan.com/"}
                target="_blank"
              />

              <VisionItems
                title={"Liquidity Locked"}
                alttitle={"Proof"}
                href={"https://www.pinksale.finance/"}
                target="_blank"
              />
            </div>

            <div className="flex mt-2 font-hauora">
              <p>Scroll to: </p>
              <ScrollItems href={"#tokenomics"} item={"Tokenomics"} />
              <ScrollItems href={"#howtobuy"} item={"How to buy"} />
              <ScrollItems href={"#roadmap"} item={"Roadmap"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
