import React from "react";
import ProjectsItem from "./ProjectsItem";

function Projects() {
  return (
    <div
      className="flex flex-col w-full bg-cover bg-gradient-to-t to-white from-herocolor font-hauora"
      id="projects"
    >
      <div className="backdrop-contrast-75 backdrop-blur-sm flex flex-col items-center justify-evenly h-auto w-auto bg-cover  ">
        <section class="pt-10 lg:pt-10 pb-12 lg:pb-[90px] ">
          <div class="container">
            <div class="flex flex-wrap -mx-4 ">
              <div class="w-full px-4 ">
                <div class="text-center mx-auto mb-12 lg:mb-10 max-w-[510px] ">
                  <h2
                    class="
                  text-5xl
                  text-herocolor
                  mb-4 font-semibold z-10
                  "
                  >
                    Together, We Cast Powerful Spells
                  </h2>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-4 p-4">
              <div
                class="w-full md:w-1/2 lg:w-1/3
                px-4 "
              >
                <div
                  class="
               p-5
               md:px-7
               xl:px-10
               rounded-[20px]
               bg-white
               shadow-md
               hover:shadow-lg
               mb-8
             h-[250px]
             bg-gradient-to-b from-gray-200 to-herocolor
            
        
               "
                >
                  {" "}
                  <div className="flex items-center">
                    <h4 class="font-semibold text-2xl text-dark mb-6 flex place-items-center px-2">
                      Uncatchable Magic
                    </h4>{" "}
                  </div>
                  <p class="text-body-color text-white">
                    Just like the Golden Snitch in Quidditch, SnitchToken is
                    uncatchable by other tokens. It dances through the crypto
                    skies, evading any attempt to seize its magic. Other tokens
                    may try, but the Snitch remains elusive and free.
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3  px-4">
                <div
                  class="
               p-5
               md:px-7
               xl:px-10
               rounded-[20px]
               bg-white
               shadow-md
               hover:shadow-lg
               mb-8
               h-[250px]
               bg-gradient-to-b from-gray-200 to-herocolor
               

               "
                >
                  {" "}
                  <div className="flex items-center">
                    <h4 class="font-semibold text-2xl text-dark mb-6 flex place-items-center px-2">
                      To the Moon and Beyond
                    </h4>{" "}
                  </div>
                  <p class="text-body-color text-white">
                    Snitch is not just reaching for the stars; it's aiming for
                    the moon! Join us on this moon expedition of uncatchable
                    magic, where the journey is as enchanting as the
                    destination. As we soar to new heights, Snitch aims to leave
                    its magical footprint on the moon.
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3  px-4">
                <div
                  class="
                  p-5
                  md:px-7
               xl:px-10
               rounded-[20px]
               bg-white
               shadow-md
               hover:shadow-lg
               mb-8
               h-[250px]
               bg-gradient-to-b from-gray-200 to-herocolor

               "
                >
                  {" "}
                  <div className="flex items-center">
                    <h4 class="font-semibold text-2xl text-dark mb-6 flex place-items-center px-2">
                      Wizard Governance
                    </h4>
                  </div>
                  <p class="text-body-color text-white">
                    Your magical voice matters! Engage in wizard governance
                    decisions and collectively steer the course of Snitch. Every
                    spell you cast influences the direction of our enchanted
                    community.
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 px-4 ">
                <div
                  class="
                  p-5
                  md:px-7
               xl:px-10
               rounded-[20px]
               bg-white
               shadow-md
               hover:shadow-lg
               mb-8
               h-[250px]
               bg-gradient-to-b from-gray-200 to-herocolor

               "
                >
                  {" "}
                  <div className="flex items-center">
                    <h4 class="font-semibold text-2xl text-dark mb-6 flex place-items-center px-2">
                      Quidditch Challenges
                    </h4>{" "}
                  </div>
                  <p class="text-body-color text-white">
                    Participate in thrilling Quidditch-inspired challenges and
                    events designed by the community, showcasing your wizarding
                    skills and earning magical rewards.
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 px-4 ">
                <div
                  class="
                  p-5
                  md:px-7
               xl:px-10
               rounded-[20px]
               bg-white
               shadow-md
               hover:shadow-lg
               mb-8
               h-[250px]
               bg-gradient-to-b from-gray-200 to-herocolor

               "
                >
                  {" "}
                  <div className="flex items-center">
                    <h4 class="font-semibold text-2xl text-dark mb-6 flex place-items-center px-2">
                      Community Development Fund
                    </h4>{" "}
                  </div>
                  <p class="text-body-color text-white">
                    Contribute to the magic! We're establishing a community
                    development fund powered by wizards, supporting innovative
                    projects and magical ideas proposed by our members.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Projects;
