import React from "react";

function VisionItems({ title, alttitle, href }) {
  return (
    <a
      className="px-4 py-2 m-2 rounded-lg bg-herocolor text-white w-44 border-none text-xs hover:scale-105 font-pressStart2P "
      href={href}
    >
      {title}
      <p className="text-xs text-gray-200">{alttitle}</p>
    </a>
  );
}

export default VisionItems;
