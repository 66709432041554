import React from "react";

function StageItems({ item }) {
  return (
    <div class=" transform border border-opacity-20 border-black transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center h-[80px] w-[300px]  px-4 py-2 bg-herocolor text-white rounded mb-6 flex-col md:flex-row space-y-4 md:space-y-0">
      <div
        className={`w-5 h-5  absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0 ${item.color}`}
      ></div>

      <div className={`w-10 h-1 absolute -left-10 z-0 ${item.color}`}></div>

      <div class="flex-auto">
        <h1 class="text-base ">{item.title}</h1>
      </div>
    </div>
  );
}

export default StageItems;
