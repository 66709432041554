import React from "react";
function Exchanges() {
  return (
    <div className="px-4">
      <div className="text-5xl mt-10  text-herocolor flex justify-center items-center ">
        <p className="mb-10 font-semibold ">Featured</p>
      </div>

      <div className="flex flex-row mb-20 justify-center ">
        <div className="flex justify-center items-center">
          <a href="https://bscscan.com/token/" target="_blank">
            <img src={"bscscan.png"} width={120} height={30} />
          </a>
        </div>
        <div className="flex justify-center items-center ml-8">
          <a href="" target="_blank">
            <img src={"cmc.svg"} width={120} height={30} />
          </a>
        </div>
        <div className="flex justify-center items-center ml-8">
          <a href="" target="_blank">
            <img src={"cg.svg"} width={120} height={30} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Exchanges;
