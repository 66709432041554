import React from "react";

function FeaturesItem({ title, desc, link }) {
  return (
    <div className="bg-herocolor border border-opacity-25 border-black bg-opacity-80 p-4 m-4 w-80 h-48  rounded-lg flex flex-col cursor-pointer items-center  transition duration-500 ease-in-out  transform  hover:scale-105 z-10  ">
      <p className="text-lg text-white p-2 font-semibold">{title}</p>
      <p className="text-gray-400 text-base">{desc}</p>
    </div>
  );
}

export default FeaturesItem;
