import React from "react";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import TeamMembers from "./TeamMembers";

function Team() {
  return (
    <div className="flex flex-col h-full w-full" id="faq">
      <div className="backdrop-contrast-75 backdrop-blur-sm flex flex-col items-center justify-evenly h-full w-auto bg-cover"></div>

      <Footer />
    </div>
  );
}

export default Team;
