import React from "react";

function Footer() {
  return (
    <div class="">
      <footer class="text-gray-600 body-font bg-gray-400">
        <div class="container px-5 py-5 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col ">
          <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <img src="/snitch.png" alt="snitch logo" width={64} height={64} />
              <span class="ml-3 text-xl text-white font-semibold font-pressStart2P">
                snitch
              </span>
            </a>
            <p class="mt-2 text-base text-white text-center font-hauora">
              A deflationary meme token with 1 % auto-burn mechanism. Snitch is
              not just a token; it's a magical community adventure.
            </p>
          </div>
          <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center font-hauora">
            <div class="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 class=" text-white text-xl font-semibold mb-3 ">Links</h2>
              <nav class="list-none mb-10">
                <li>
                  <a class="text-white hover:text-red-300" href="#">
                    Home
                  </a>
                </li>
                <li>
                  <a class="text-white hover:text-red-300" href="#tokenomics">
                    Tokenomics
                  </a>
                </li>
                <li>
                  <a class="text-white hover:text-red-300" href="#roadmap">
                    Roadmap
                  </a>
                </li>
              </nav>
            </div>

            <div class="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 class="text-white text-xl font-semibold mb-3">Connect</h2>
              <nav class="list-none mb-10">
                <li>
                  <a
                    class="text-white hover:text-red-300"
                    href="https://t.me/snitchtoken"
                    target="_blank"
                  >
                    Telegram
                  </a>
                </li>
                <li>
                  <a
                    class="text-white hover:text-red-300"
                    href="https://twitter.com/snitchtoken"
                    target="_blank"
                  >
                    Twitter
                  </a>
                </li>
              </nav>
            </div>
          </div>
        </div>
        <div class="bg-gray-900 w-full justify-center items-center flex font-hauora">
          <div class=" mx-auto py-4 px-5 flex justify-center items-center flex-col m-2 ">
            <p class="text-white text-sm text-center ">
              © Copyright Snitch. All Rights Reserved.
            </p>
            <p className="text-white text-sm text-center ">
              <a
                href="mailto:info@snitchtoken.com"
                rel="noopener noreferrer"
                class="text-gray-400 ml-1"
                target="_blank"
              >
                info@snitchtoken.com
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
