import React from "react";

function HeaderItem({ title, Icon, href }) {
  return (
    <div>
      <a
        className="group flex flex-col cursor-pointer items-center m-2 w-12 sm:w-20 "
        href={href}
      >
        <Icon className="h-6 mb-1 group-hover:animate-bounce " />
        <p className="tracking-widest opacity-0 sm:opacity-100 text-xs hidden sm:flex font-semibold font-pressStart2P">
          {title}
        </p>
      </a>
    </div>
  );
}

export default HeaderItem;
