import React from "react";
import FeaturesItem from "./FeaturesItem";

function Features() {
  return (
    <div
      className="flex flex-col bg-cover bg-[#E1D2EE] font-hauora "
      id="tokenomics"
    >
      <div className="backdrop-contrast-75 backdrop-blur-sm flex flex-col items-center justify-evenly h-auto w-auto bg-cover   ">
        <div className="text-5xl mt-10 text-herocolor font-extrabold z-10  ">
          <p>Tokenomics</p>
        </div>
        <div className="flex flex-col sm:flex-col md:flex-row  justify-center items-center mt-10 mb-10 w-full h-auto z-10 ">
          <FeaturesItem
            title={"Fee Structure"}
            desc={
              "Every transaction in the wizarding world of Snitch is imbued with magic, featuring a 5% transaction fee."
            }
          />
          <FeaturesItem
            title={"Auto Burn"}
            desc={`Witness the magical burn of 1% of tokens in each transaction, creating scarcity and enhancing the magical value of Snitch as it journeys to the moon and beyond.`}
          />
          <FeaturesItem
            title={"Auto allocation to Liquidity Pool"}
            desc={"1% of taxes will be allocated to the Liquidity Pool."}
          />
          <FeaturesItem
            title={"Massive Marketing"}
            desc={
              "3% of taxes will be assigned to the marketing wallet which will be used for the future development of the project."
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Features;
